import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import {
  createFeatureToggleServiceV2,
  useAuthenticationService,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { CategoryIcon, ProductIcon } from 'assets/icons';
import AppbarConfig from 'components/AppbarConfig/AppbarConfig';
import AppContainer from 'components/AppContainer/AppContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { CategoryProvider } from 'context/CategoryContext';
import { StoreProvider } from 'context/store-context';
import { ToastProvider } from 'context/toast-context';
import { ToggleProvider } from 'context/toggle-context';
import { UserProvider } from 'context/user-context';
import { VendorProvider } from 'context/vendor-context';
import { ZoneProvider } from 'context/zone-context';
import fetch from 'cross-fetch';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getI18n } from 'react-i18next';
import { defaultTheme } from 'supplier-portal-shared-components';
import customTheme from 'themes/customTheme';
import i18nInit from './i18n/i18n-init';
import Router from './Router';
import { isCountryInEuropeanCluster } from './utils/countries-in-european-cluster';

type ENVS = {
  qa: string;
  dev: string;
  uat: string;
  prod: string;
};

export const getEndpointByCountry = (): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selectedCountry = localStorage.getItem('selectedCountry') ?? '';

  if (isCountryInEuropeanCluster(selectedCountry)) {
    return `/api/eu/catalog-admin-graphql-service/`;
  }
  return '/api/catalog-admin-graphql-service/';
};

// Set the endpoint of the GraphQL service based on the environment
const portalConfig = localStorage.getItem('portal-config');
const env = JSON.parse(portalConfig as string)?.ENV as keyof ENVS;
// istanbul ignore next
const serverUrl = getEndpointByCountry();

// istanbul ignore next
const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/' : serverUrl,
  fetch,
});
// FIXME: Find out how to properly test authLinks for Apollo Client
/* istanbul ignore next */
const authLink = setContext((_, { headers }) => {
  const authentication = useAuthenticationService();
  const selectedCountry = localStorage.getItem('selectedCountry');
  // Get the authentication token from local storage if it exists
  const token = authentication.getAuthHeader();

  // Return the headers to the context so httpLink can read them
  return {
    ...(process.env.NODE_ENV !== 'development' && { uri: getEndpointByCountry() }),
    headers: {
      ...headers,
      token: token || '',
      Authorization: token ?? '',
      country: selectedCountry,
      store: localStorage.getItem('store_Id'),
      vendor: localStorage.getItem('vendor_Id'),
      env,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'admin-portal-browse-category-marketplace-mfe',
  seed: 'admin-portal-browse-category-marketplace-mfe',
});

function App({
  optimizelyKey,
  segmentKey,
}: {
  optimizelyKey: string;
  segmentKey: string;
}): JSX.Element {
  const isFeatureToggleInstanceCreated =
    window.services?.FeatureToggleOptimizelyV2 &&
    // @ts-expect-error allow type any for services object
    window.services?.FeatureToggleOptimizelyV2['admin-portal-browse-category-mfe'];

  if (!isFeatureToggleInstanceCreated) {
    createFeatureToggleServiceV2('admin-portal-browse-category-mfe', optimizelyKey);
  }

  const { data, isLoading } = useUserMetadata();
  const lng = data?.profile?.preferredLanguage;
  i18nInit(lng);

  const { initSegment } = useSegmentAnalytics();
  initSegment(segmentKey);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isLoading) {
      getI18n().changeLanguage(lng);
    }
  }, [lng, isLoading]);

  const menu = [
    {
      id: '1',
      title: getI18n().t('Menu.categories'),
      icon: CategoryIcon,
      path: '/category-marketplace',
    },
    {
      id: '2',
      title: getI18n().t('Menu.products'),
      icon: ProductIcon,
      path: '/category-marketplace/products',
    },
  ];

  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <AppbarConfig sidebarItems={menu}>
          <ThemeProvider theme={defaultTheme}>
            <StylesProvider generateClassName={generateClassName} injectFirst>
              <ThemeProvider theme={customTheme}>
                <UserProvider>
                  <ZoneProvider>
                    <StoreProvider>
                      <ToggleProvider>
                        <VendorProvider>
                          <CategoryProvider>
                            <ToastProvider>
                              <DndProvider backend={HTML5Backend}>
                                <AppContainer>
                                  <Router />
                                </AppContainer>
                              </DndProvider>
                            </ToastProvider>
                          </CategoryProvider>
                        </VendorProvider>
                      </ToggleProvider>
                    </StoreProvider>
                  </ZoneProvider>
                </UserProvider>
              </ThemeProvider>
            </StylesProvider>
          </ThemeProvider>
        </AppbarConfig>
      </ErrorBoundary>
    </ApolloProvider>
  );
}

export default App;
